<template>
  <div class="main">
    <Header location="login" />
    <div class="container">
      <!-- Content here -->
      <p class="text-center my-5" style="font-size: 1.5rem">登录泛函管理平台</p>
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item prop="username">
              <el-input v-model="form.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="form.password" placeholder="请输入密码" required></el-input>
            </el-form-item>
            <el-form-item prop="remember">
              <el-checkbox v-model="form.remember">记住我</el-checkbox>
              <!-- <el-button type="text" style="float:right">找回密码</el-button> -->
              <a href="" style="float:right">找回密码</a>
            </el-form-item>
            <el-button type="primary" @click="login('form')" style="width:100%">登录</el-button>
          </el-form>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import md5 from 'js-md5'
import { signIn } from '@/api/login'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      form: {
        username: '',
        password: '',
        remember: true
      },
      // 表单验证规则
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { type: 'string', min: 5, message: '用户名至少为 5 位字符', trigger: 'blur' },
          { type: 'string', max: 30, message: '用户名最多为 30 位字符', trigger: 'blur' },
          { type: 'string', message: '用户名须以字母开头，且只能包含字母、数字、横线及下划线', pattern: /^[a-zA-Z][a-zA-Z0-9_-]{4,29}$/, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        remember: [
          { required: true, type: 'boolean', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    login (form) {
      this.$refs[form].validate((valid) => {
        // 表单验证
        if (valid) {
          this.loginPost(this.form)
        }
      })
    },
    loginPost (form) {
      signIn({
        user: this.form.username,
        password: md5(this.form.password)
      }).then(
        res => {
          this.$notify({
            title: '成功',
            type: 'success',
            message: res.message,
            duration: 2000
          })
          this.$router.push('/home')
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    }
  }
}
</script>
